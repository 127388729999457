module.exports = {
  //-- SITE SETTINGS -----
  author: "Matthew Jones",
  siteTitle: "Printamatt - Printmaking portfolio and shop",
  siteShortTitle: "Printamatt", // Used as logo text in header, footer, and splash screen
  siteDescription: "Printamatt - Matthew Jones printmaking portfolio and shop",
  siteUrl: "https://art.jonesmwh.com",
  siteLanguage: "en_GB",
  siteIcon: "content/favicon.png", // Relative to gatsby-config file
  seoTitleSuffix: "Printmaking portfolio and shop", // SEO title syntax will be e.g. "Imprint - {seoTitleSuffix}"
  useCookieBar: false, // If you use Google Analytics and want to be GDPR-compliant, set it to true
  googleAnalyticsTrackingId: "", // e.g. UA-XXXXXX-X

  // -- THEME SETTINGS -----
  colors: {
    lightTheme: {
      primary: "#000000",
      highlight: "#FFFFFF", //eac820  d8ae48
      accent: "#eac820", //eac820  d8ae48
      secondary: "#eac820",
      tertiary: "#eac820",
      text: "#000000",
      subtext: "#555555",
      background: "#FFFFFF",
      darktone: "#000000",
      midtone: "#DCDCDC",
      card: "#FFFFFF",
      scrollBar: "rgba(0, 0, 0, 0.5)",
      boxShadow: "rgba(0, 0, 0, 0.16)",
      boxShadowHover: "rgba(0, 0, 0, 0.32)",
    },
    darkTheme: {
      primary: "#FAFAFA",
      highlight: "#FFFFFF",
      accent: "#eac820", //eac820  d8ae48
      secondary: "#2A2926",
      tertiary: "#252525",
      text: "rgba(255, 255, 255, 0.87)",
      subtext: "#AAAAAA",
      background: "#121212",
      midtone: "#DCDCDC",
      darktone: "#000000",
      card: "#1C1C1C",
      scrollBar: "rgba(255, 255, 255, 0.5)",
      boxShadow: "rgba(0, 0, 0, 0.16)",
      boxShadowHover: "rgba(0, 0, 0, 0.32)",
    },
  },

  
  fonts: {
    primary: "sans-serif",
  },

  //-- NAVIGATION SETTINGS -----
  navLinks: {
    menu: [
      {
        name: "Art",
        url: "/#art",
      },
      {
        name: "About",
        url: "/#about",
      },
      {
        name: "Contact",
        url: "/#contact",
      },

    ],
  },
  footerLinks: [
    {
      name: "Copyright",
      url: "/copyright",
    },
  ],
}
